.contact-section {
	padding: 1rem;
	background-color: #e3e4ed;
	z-index: 1;
}

h4 {
	margin-top: 1rem;
}

.field {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.field span {
	font-weight: bold;
	font-size: 1.125rem;
}

.field input {
	height: 2rem;
	border: none;
	padding-left: 0.5rem;
	border: 1px solid #858282;
	border-radius: 4px;
	font-size: 1rem;
	outline: none;
	transition: border 200ms ease-in-out, color 200ms ease-in-out;
	font-family: 'Frank Ruhl Libre', serif;
}

.field textarea {
	border: none;
	padding: 0.5rem;
	border: 1px solid #858282;
	border-radius: 4px;
	font-size: 1rem;
	outline: none;
	transition: border 200ms ease-in-out, color 200ms ease-in-out;
	font-family: 'Frank Ruhl Libre', serif;
}

.field input:not(.inputError):focus,
.field textarea:not(.inputError):focus {
	border-color: #000;
}

@media only screen and (min-width: 756px) {
	.contact-inner {
		display: grid;
		grid-template-columns: 45% 10% 45%;
		/* column-gap: 4rem; */
	}

	.right {
		place-content: end;
		grid-column: 3;
	}
}
