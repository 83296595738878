.about-section {
	padding: 1rem;
}

.about-section p {
	margin: 1rem 0;
}

.emphasis {
	font-size: 1.125rem;
	font-weight: 700;
}
