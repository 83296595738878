p {
	margin: 1rem 0;
}

.visual {
	background-color: #030613;
	padding: 2rem 1rem;
	text-align: center;
	margin-top: 1rem;
}

.visual h3 {
	margin-bottom: 1rem;
}

.specialties {
	display: flex;
	justify-content: space-around;
	max-width: 662px;
	left: 0;
	right: 0;
	margin: auto;
}

.specialty-circle {
	background-color: #fff;
	border-radius: 9999px;
	display: flex;
	flex-direction: column;
	width: 30%;
	max-width: 142px;
	aspect-ratio: 1/1;
	justify-content: center;
	align-items: center;
}

.specialty-circle span {
	font-size: 15px;
}
