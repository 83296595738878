div {
	position: relative;
}

h2 {
	position: relative;
	z-index: 2;
}

.underline {
	height: 10px;
	width: 80px;
	background-color: #00b2ff;
	position: absolute;
	bottom: 2px;
}
