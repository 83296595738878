.contactButton {
	position: relative;
	overflow: hidden;
	outline: none;
	border: none;
	padding: 10px 24px;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	font-size: inherit;
}

@keyframes ripple {
	to {
		transform: scale(4);
		opacity: 0;
	}
}

.ripple {
	position: absolute;
	border-radius: 50%;
	transform: scale(0);
	animation: ripple 500ms linear;
	background-color: rgba(255, 255, 255, 0.5);
}
