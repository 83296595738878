.p-1 {
	padding: 1rem;
}

.text-bold {
	font-weight: 700;
}

.text-light {
	color: #fff;
}

.bg-light {
	background: #fff;
}
