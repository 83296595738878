.footer-section {
	position: relative;
	width: 100%;
	height: 237px;
	background-image: url('../../../../public/assets/images/under-the-hood.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 0;
	overflow: hidden;
}

.footer-cover {
	background-color: #0b0a0aeb;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.footer-circle {
	background-color: white;
	width: 284px;
	height: 284px;
	border-radius: 50%;
	position: absolute;
	left: -164px;
	bottom: -262px;
}

.footer-circle a {
	color: inherit;
	font-weight: inherit;
}

.footer-logo {
	position: absolute;
	left: 171px;
	top: 50%;
	transform: translateY(-50%);
}

.contact-info {
	position: absolute;
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 4px;
}

.phone-number {
	right: -130px;
	top: 85px;
}

.email {
	right: -238px;
	top: 127px;
}

.address {
	right: -178px;
	top: 166px;
}

.icon-back {
	width: 32px;
	height: 32px;
	background: white;
	border: 2px solid black;
	border-radius: 99px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.owner {
	color: white;
	text-align: right;
	padding: 0.5rem 1rem;
}

.copy {
	color: white;
	font-size: 12px;
	position: absolute;
	bottom: 0;
	margin: 0;
	text-align: center;
	left: 115px;
}

.copy p {
	margin-bottom: 0.5rem;
}

@media only screen and (min-width: 756px) {
	.owner {
		padding: 2rem;
	}

	.copy {
		left: 0;
		right: 0;
		margin: auto;
		font-size: 1rem;
		padding-bottom: 0.5rem;
	}

	.footer-section {
		height: 305px;
	}

	.footer-circle {
		width: 344px;
		height: 344px;
		left: -160px;
		bottom: -328px;
	}

	.icon-back {
		width: 38px;
		height: 38px;
	}

	.phone-number {
		right: -130px;
		top: 95px;
	}

	.email {
		right: -243px;
		top: 148px;
	}

	.address {
		right: -179px;
		top: 200px;
	}
}
