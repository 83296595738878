@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body {
	font-family: 'Frank Ruhl Libre', serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Rubik', sans-serif;
}

:root {
	--disabled: #848a9c;
}

.text-block {
	max-width: 982px;
	left: 0;
	right: 0;
	margin: auto;
}

.text-3xl {
	font-size: 1.75rem;
}

.text-4xl {
	font-size: 2rem;
}

.text-6xl {
	font-size: 3rem;
}

.text-xl {
	font-size: 1.1rem;
}

.p-12 {
	padding: 3rem !important;
}

.pb-12 {
	padding-bottom: 3rem !important;
}

@media only screen and (min-width: 756px) {
	p {
		font-size: 1.1rem;
	}
}
