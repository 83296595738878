.header {
	padding: 0.5rem;
}

.info {
	text-align: center;
	margin-top: 1rem;
}

.info h1 {
	margin-bottom: 1rem;
}

@media only screen and (min-width: 1200px) {
	.info h3 {
		max-width: 522px;
	}
}
@media only screen and (min-width: 756px) and (max-width: 1200px) {
	.header {
		padding-bottom: 3rem;
	}
}

.header-image {
	width: 100%;
	position: relative;
	z-index: 1;
}

.cta-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

.cta {
	position: relative;
	overflow: hidden;
	outline: none;
	border: none;
	padding: 10px 24px;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	font-size: inherit;
	background: hsl(198, 100%, 50%);
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	font-size: inherit;
	border: none;
	cursor: pointer;
	width: 100%;
	color: #fff !important;
	transition: all 200ms ease-in-out;
	width: fit-content;
}

.header-desktop {
	background-color: #030613;
	color: white;
	height: 442px;
	display: grid;
	grid-template-columns: 35% 75%;
	z-index: 0;
	justify-items: center;
}

.circle {
	width: 568px;
	height: 568px;
	position: absolute;
	background-color: white;
	left: -150px;
	top: -62px;
	border-radius: 50%;
}

.circle-logo {
	position: absolute;
	left: 206px;
	top: 135px;
}

.left {
	justify-self: flex-start;
}

.header-right {
	grid-column: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 133px 0 50px 0;
	right: 8vw;
}

@media only screen and (min-width: 756px) {
	.header-image {
		height: 32vw;
		object-fit: cover;
		object-position: 0% 92%;
	}
}
